<template>
  <form
    class="w-64 ml-auto mr-auto grid gap-4"
    @submit.prevent="submit"
    id="form_user_forgot_password"
  >
    <FormAlert v-if="alert" :message="$t(alert.message)" :type="alert.type" />

    <FormInput
      ref="emailinput"
      :modelValue="email"
      type="email"
      required
      placeholder="Email"
      @update:modelValue="$emit('update:email', $event)"
    />
    <CommonSpinner v-if="loading" />
    <input
      v-else
      type="submit"
      class="cursor-pointer py-3 px-4 text-white article-label !font-medium w-full"
      :value="$t('sendResetLink')"
      :class="
        isMm()
          ? 'rounded-md bg-black transform translate-y-px'
          : 'rounded-full bg-blue hover:bg-denimblue'
      "
    />
  </form>
</template>

<script setup lang="ts">
const { sendPasswordResetEmail } = useEmailFlow()

const props = defineProps<{
  email: string
}>()

const loading = ref(false)
const alert = ref<{ message: string; type: AlertType } | undefined>(undefined)

async function submit() {
  loading.value = true
  alert.value = undefined
  const { err } = await sendPasswordResetEmail(props.email)
  if (err) {
    loading.value = false
    alert.value = {
      message: 'errorOccurred',
      type: ALERT_TYPE.ERROR,
    }
    return
  }

  loading.value = false
  alert.value = {
    message: 'EmailSent',
    type: ALERT_TYPE.SUCCESS,
  }
}
</script>
