<template>
  <div class="text-center" ref="loginModal">
    <div v-if="!inModal" class="label-s text-blue uppercase font-bold mb-4">
      <template v-if="page === 'login'">
        {{ $t('Login') }}
      </template>
      <template v-else-if="page === 'forgotPassword'">
        {{ $t('ForgotPassword') }}
      </template>
      <template v-else-if="page === 'ssoLogin'">
        {{ $t('sso.LoginWithSSO') }}
      </template>
    </div>

    <template v-if="['login'].includes(page)">
      <LoginUserLogin
        ref="userLogin"
        :email="email"
        :password="password"
        :allow-wayf="allowWayf"
        @update:email="$emit('update:email', $event)"
        @update:password="$emit('update:password', $event)"
      >
        <div class="mt-3 grid gap-3">
          <span
            v-if="config.public.site.allowSingleSignOn"
            class="text-sm font-sans font-medium text-blue underline cursor-pointer hover:no-underline"
            @click.stop="navigate('ssoLogin', $t('sso.LoginWithSSO'))"
            >{{ $t('sso.LoginWithSSO') }}</span
          >
          <span
            class="text-sm font-sans font-medium text-blue underline cursor-pointer hover:no-underline"
            @click.stop="navigate('forgotPassword', $t('ForgotPassword'))"
            >{{ $t('ForgottenPassword') }}</span
          >
        </div>
        <slot />
      </LoginUserLogin>
    </template>

    <template v-if="['forgotPassword'].includes(page)">
      <LoginForgotPassword
        :email="email"
        @update:email="$emit('update:email', $event)"
      />
    </template>

    <template v-if="['ssoLogin'].includes(page)">
      <LoginSSOLogin
        :email="email"
        @update:email="$emit('update:email', $event)"
      />
    </template>

    <div
      v-if="isMm() && errorRaw && errorRaw === 'createuser'"
      class="list-label text-red mt-2"
    >
      For at kunne læse Mandag Morgen beder vi dig derfor om at oprette en konto
      <a href="https://www.mm.dk/usercreatemm">her</a>.
    </div>

    <div v-if="!inModal && backPages.length > 0" class="mt-2">
      <span
        class="text-sm font-sans font-medium text-blue underline cursor-pointer hover:no-underline"
        @click.stop="back"
        >{{ $t('BackToLogin') }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

const props = withDefaults(
  defineProps<{
    tryLogin?: boolean
    inModal?: boolean
    allowWayf?: boolean
    hasBack?: boolean
    title?: string
    initialPage?: string
    email?: string
    password?: string
  }>(),
  {
    allowWayf: true,
    title: '',
    initialPage: 'login',
    email: '',
    password: '',
  }
)

const { email, password } = toRefs(props)

const errorRaw = ref<string>()
const backTitles = ref<string[]>([])
const backPages = ref<string[]>([])
const page = ref<string>(props.initialPage)

const userLogin = ref<HTMLInputElement>()
const emit = defineEmits<{
  (e: 'update:title', value: string | undefined): void
  (e: 'update:email', value: string): void
  (e: 'update:password', value: string): void
  (e: 'update:has-back', value: boolean): void
}>()

function back() {
  const title = backTitles.value.shift()
  emit('update:title', title)

  const backPage = backPages.value.shift()
  if (backPages.value.length == 0) {
    emit('update:has-back', false)
  }
  if (backPage) {
    page.value = backPage
  }
}
function navigate(newPage: string, newTitle = '') {
  backTitles.value.unshift(props.title)
  emit('update:title', newTitle)
  backPages.value.unshift(page.value)
  page.value = newPage
  emit('update:has-back', true)
}
</script>

<style scoped>
.input-field {
  @apply text-black;
}

.input-field,
.select-field {
  @apply mt-4 w-full flex-grow appearance-none border py-2 px-4 font-medium text-black leading-tight;
}

.select-field {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.871 1.896-3.225 3.25a.895.895 0 0 1-.583.229.804.804 0 0 1-.584-.229l-3.225-3.25a.786.786 0 0 1-.203-.888A.832.832 0 0 1 1.813.5h6.474c.33 0 .61.203.736.508.127.304.077.66-.152.888Z' fill='%23282828'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  color: rgb(160, 174, 192);
}
</style>
